import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "../src/components/SignIn.vue";
import SignOut from "../src/components/SignOut.vue";
import Home from "../src/components/Home.vue";
import FemaleRank from "../src/components/FemaleRank";
import FemaleApproval from "../src/components/FemaleApproval";
import MaleApproval from "../src/components/MaleApproval";
import Approval from "../src/components/Approval";
import MaleRank from "../src/components/MaleRank";
import ImageCheck from "../src/components/ImageCheck";
import ReportCheck from "../src/components/ReportCheck";
import AudioList from "../src/components/AudioList";
import Calling from "../src/components/Calling";
import ActionLog from "../src/components/ActionLog";
import Room from "../src/components/Room";
import Feed from "../src/components/Feed";
import FeedContent from "../src/components/FeedContent";
import FeedComment from "../src/components/FeedComment";
import FeedFollow from "../src/components/FeedFollow";
import FeedBlock from "../src/components/FeedBlock";
import FeedContentSearch from "../src/components/FeedContentSearch";
import FeedCommentSearch from "../src/components/FeedCommentSearch";
import SendbirdMessage from "../src/components/SendbirdMessage";
import CallContent from "../src/components/CallContent";
Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "signin",
      component: SignIn,
      props: true,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      props: true,
    },
    {
      path: "/signout",
      name: "signout",
      component: SignOut,
      props: true,
    },
    {
      path: "/approval",
      name: "approval",
      component: Approval,
      props: true,
    },
    {
      path: "/femaleapproval",
      name: "femaleapproval",
      component: FemaleApproval,
      props: true,
    },
    {
      path: "/maleapproval",
      name: "maleapproval",
      component: MaleApproval,
      props: true,
    },
    {
      path: "/femalerank",
      name: "femalerank",
      component: FemaleRank,
      props: true,
    },
    {
      path: "/malerank",
      name: "malerank",
      component: MaleRank,
      props: true,
    },
    {
      path: "/audiolist",
      name: "audiolist",
      component: AudioList,
      props: true,
    },
    {
      path: "/imagecheck",
      name: "imagecheck",
      component: ImageCheck,
      props: true,
    },
    {
      path: "/reportcheck/:country",
      name: "reportcheck",
      component: ReportCheck,
      props: true,
    },
    {
      path: "/calling",
      name: "calling",
      component: Calling,
      props: true,
    },
    {
      path: "/audiolist/room/:roomid",
      name: "room",
      component: Room,
      props: true,
    },
    {
      path: "/actionlog",
      name: "actionlog",
      component: ActionLog,
      props: true,
    },
    {
      path: "/feeds",
      name: "feeds",
      component: Feed,
      props: true,
    },
    {
      path: "/user/:user_id/comment/:comment_id",
      name: "feedComment",
      component: FeedComment,
      props: true,
    },
    {
      path: "/feeds/feed/:id",
      name: "feedContent",
      component: FeedContent,
      props: true,
    },
    {
      path: "/feeds/search/feed",
      name: "feedContentSearch",
      component: FeedContentSearch,
      props: true,
    },
    {
      path: "/feeds/search/comment",
      name: "feedCommentSearch",
      component: FeedCommentSearch,
      props: true,
    },
    {
      path: "/feeds/follow",
      name: "feedFollow",
      component: FeedFollow,
      props: true,
    },
    {
      path: "/feeds/block",
      name: "feedBlock",
      component: FeedBlock,
      props: true,
    },
    {
      path: "/sendbirdmessage/:channelUrl",
      name: "SendbirdMessage",
      component: SendbirdMessage,
      props: true,
    },
    {
      path: "/callContent/:roomId",
      name: "CallContent",
      component: CallContent,
      props: true,
      beforeEnter: (to, from, next) => {
        if (!to.params.roomId) {
          next({ name: "404" });
        } else {
          next();
        }
      },
    },
  ],
});
