export const ToastConfig = {
  common: {
    position: "top",
    duration: 1200,
  },

  error: {
    position: "top",
    duration: 5000,
  },
};
