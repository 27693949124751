import { render, staticRenderFns } from "./FeedComment.vue?vue&type=template&id=75513c74&scoped=true&"
import script from "./FeedComment.vue?vue&type=script&lang=js&"
export * from "./FeedComment.vue?vue&type=script&lang=js&"
import style0 from "./FeedComment.vue?vue&type=style&index=0&id=75513c74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75513c74",
  null
  
)

export default component.exports