import moment from "moment-timezone";
export function getReasonText(reasonVal, reasonOptions) {
  for (let i = 0; i < reasonOptions.length; i++) {
    if (reasonVal == reasonOptions[i].value) {
      return reasonOptions[i].text;
    }
  }
  return null;
}

export function getMidTypeText(midType, midCategory, loginCountry) {
  for (let i = 0; i < midCategory[loginCountry].length; i++) {
    if (midType == midCategory[loginCountry][i].value) {
      return midCategory[loginCountry][i].text;
    }
  }
}

export function getReportCategories() {
  return [
    "CALL",
    "CHAT",
    "CONVERSATION",
    "FEED",
    "COMMENT",
    "SWIPE",
    "PROFILE",
  ];
}

export function getTypeText(type, typeString) {
  for (let i = 0; i < typeString.length; i++) {
    if (type == typeString[i].value) {
      return typeString[i].text;
    }
  }
  return null;
}

export function formatReportHistory(
  reports,
  midCategory,
  loginCountry,
  typeString
) {
  const formattedReports = [];
  for (let i = 0; i < reports.length; i++) {
    const report = reports[i];
    const formattedReport = {
      type: report.domain,
      subType: getTypeText(report.report_type, typeString),
      content: report.report_content,
      date: moment(report.created_at).format("YY/MM/DD_HH:mm"),
    };
    formattedReports.push(formattedReport);
  }
  return formattedReports;
}

export function formatReasonOptions(
  reasonOptions,
  userControlScoreByControlReason
) {
  return reasonOptions.map((reason) => {
    let prefix_text = "";
    let score = userControlScoreByControlReason.find(
      (score) => score?.key == reason.value
    );
    if (!score) {
      return reason;
    }
    let index = reason.text.lastIndexOf("]");
    if (index != -1) {
      reason.text = reason.text.slice(index + 1);
    }
    if (score?.key.startsWith("FEED") && !reason.text.endsWith(" (피드)")) {
      prefix_text = "[클연 " + score.score + "점] ";
      reason.text = prefix_text + reason.text + " (피드)";
    } else {
      prefix_text = "[클연 " + score.score + "점] ";
      reason.text = prefix_text + reason.text;
    }
    return reason;
  });
}

export function formatSilenceRatio(silenceMetadata, userId, targetUserId) {
  const userSilenceMetadata = silenceMetadata.find(
    (metadata) => metadata.user_id == userId
  );
  const targetUserSilenceMetadata = silenceMetadata.find(
    (metadata) => metadata.user_id == targetUserId
  );

  if (!userSilenceMetadata || !targetUserSilenceMetadata) {
    return "데이터 없음";
  }

  return `신고자: ${userSilenceMetadata?.value}%, 신고 대상자: ${targetUserSilenceMetadata?.value}%`;
}

export function getReportTargetObjRouteParam(
  reportCategory,
  reportObjId,

  targetId,
  userId,
  targetUserId
) {
  let routeName, routeParams, query;

  switch (reportCategory) {
    case "FEED":
      routeName = "feedContent";
      routeParams = { id: targetId };
      break;

    case "COMMENT":
      routeName = "feedComment";
      routeParams = { user_id: userId, comment_id: targetId };
      break;

    case "CHAT":
    case "CONVERSATION":
      routeName = "SendbirdMessage";
      routeParams = { channelUrl: reportObjId };
      break;
    case "CALL":
      routeName = "CallContent";

      routeParams = {
        roomId: reportObjId,
      };
      query = {
        userId,
        targetUserId: targetUserId,
      };
      break;
    default:
      return;
  }

  return { name: routeName, params: routeParams, query };
}
