<template>
  <div id="app">
    <NavComponent />
    <b-col cols="4">
      <div style="display: flex; flex-direction: row; align-items: center">
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-dropdown
            id="dropdown-1"
            :text="dropdownHideFilterType"
            class="m-md-2"
          >
            <b-dropdown-item @click="changeHideCategoryFilterDropbox('all')"
              >all</b-dropdown-item
            >
            <b-dropdown-item @click="changeHideCategoryFilterDropbox('none')"
              >none</b-dropdown-item
            >
            <b-dropdown-item
              @click="changeHideCategoryFilterDropbox('전체숨김')"
              >전체숨김</b-dropdown-item
            >
            <b-dropdown-item
              @click="changeHideCategoryFilterDropbox('신고누적숨김')"
              >신고누적숨김</b-dropdown-item
            >
            <b-dropdown-item @click="changeHideCategoryFilterDropbox('작외숨')"
              >작외숨</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <b-dropdown id="dropdown-1" :text="dropdownSearchType" class="m-md-2">
          <b-dropdown-item @click="changeDropboxSearchType('email')"
            >email</b-dropdown-item
          >
          <b-dropdown-item @click="changeDropboxSearchType('callRoom')"
            >callRoom</b-dropdown-item
          >
          <b-dropdown-item @click="changeDropboxSearchType('text')"
            >text</b-dropdown-item
          >
        </b-dropdown>
        <p style="margin-top: 30px; margin-bottom: 30px">
          <b-input-group class="mt-1">
            <b-form-input
              v-model="keyword"
              @keyup.enter="getCommentByEmail()"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="info" @click="getCommentByEmail()">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </p>
        <p
          v-if="isSearch"
          style="margin-top: 30px; margin-left: 10px; margin-bottom: 30px"
        >
          <b-input-group class="mt-1">
            <b-input-group-append>
              <b-button variant="info" @click="getDefaultSearch()">
                Default
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </p>
      </div>
      <div
        style="display: flex; justify-content: flex-start; margin-left: 10px"
      >
        <b-p v-if="isSearch">
          {{ dropdownSearchType }}: "{{ searchedKeyword }}" 로 검색한
          결과입니다.</b-p
        >
      </div>
    </b-col>
    <b-col>
      <div style="display:flex; width:100%; justify-content: flex-start;">
      <div style="display: flex; flex-direction: row; align-items: center">
        <b-dropdown id="dropdown-1" :text="dropdownHideType" class="m-md-2">
          <b-dropdown-item @click="changeDropboxHideType('none')"
            >none</b-dropdown-item
          >
          <b-dropdown-item @click="changeDropboxHideType('전체숨김')"
            >전체숨김</b-dropdown-item
          >
          <b-dropdown-item @click="changeDropboxHideType('신고누적숨김')"
            >신고누적숨김</b-dropdown-item
          >
          <b-dropdown-item @click="changeDropboxHideType('작외숨')"
            >작외숨</b-dropdown-item
          >
        </b-dropdown>

        <b-button class="mr-2" size="sm" @click="hideActionButton()">
          액션
        </b-button>
      </div>
      <div style="display: flex; flex-direction: row; align-items: center">
        <b-dropdown id="dropdown-1" :text="dropdownReportType" class="m-md-2">
          <b-dropdown-item v-for="text in this.feedReasonList" :key="text" @click="changeDropboxReportType({ text })"
            >{{text}}</b-dropdown-item
          >
        </b-dropdown>
        <b-button class="mr-2" size="sm" @click="createReportButton()">
          신고
        </b-button>
      </div>
    </div>
    </b-col>
    <b-row>
      <b-col cols="12">
        <b-table
          hover
          :items="commentList"
          :fields="commentTableFields"
          small
        >
          <template #cell(user.uuid)="row">
            <b-form-checkbox
              v-model="selectedComments[row.item.comment.comment_id]"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
              @change="checkboxChange(row.item.comment.comment_id, row.item.user.uuid)"
            >
            </b-form-checkbox>
          </template>

            <template #cell(comment.is_delete)="row">
          <b-icon :icon="row.item.comment.is_delete ? 'check-circle' : 'x-circle'" :variant="row.item.comment.is_delete ? 'success' : 'danger'"></b-icon>
        </template>
          <template #cell(comment.comment_id)="row">
            <b-button
              size="sm"
              class="mr-2"
              @click="
                openFeedComment(row.item.user.uuid, row.item.comment.comment_id)
              "
            >
              VIEW
            </b-button>
          </template>

          <template #cell(id)="row">
            <b-button
              class="mr-2"
              size="sm"
              style="width: 100px; word-break: break-all"
              @click="openFeedComment(row.item.user.data.uuid, row.item.id)"
            >
              {{ row.item.id }}
            </b-button>
          </template>

          <template #cell(comment.feedpost_id)="row">
            <b-button
              class="mr-2"
              size="sm"
              @click="
                openFeedContent(row.item.comment.feedpost_id, row.item.comment)
              "
              style="width: 100px; word-break: break-all"
            >
              VIEW
            </b-button>
          </template>

          <template #cell(user.email)="row">
            <b-button
              class="mr-2"
              size="sm"
              @click="openAdminUserPage(row.item.user.email)"
              style="width: 100px; word-break: break-all"
            >
              {{ row.item.user.email }}
            </b-button>
          </template>
          <template>

          </template>
          <template #cell(comment.updated_at)="row">
            {{ getTimeStamp(row.item.comment.updated_at) }}
          </template>
          <template #cell(comment.created_at)="row">
            {{ getTimeStamp(row.item.comment.created_at) }}
          </template>
          <template #cell(comment.content.text)="row">
            <div style="width: 300px; word-break: break-all">
              {{ row.item.comment.content.text }}
            </div>
          </template>
          <template #cell(comment.mentioned_user.email)="row">
            
            
            <b-button
              v-if="row.item.comment.mentioned_user.email"
              class="mr-2"
              size="sm"
              @click="openAdminUserPage(row.item.comment.mentioned_user.email)"
              style="width: 100px; word-break: break-all"
            >
              {{ row.item.comment.mentioned_user.email }}
            </b-button>

          </template>
        </b-table>
      </b-col>

      <!-- <b-col cols="6">
                <h5>
                    <b-badge>대댓글</b-badge>
                </h5>
                <b-table 
                    hover 
                    :items="bigCommentList" 
                    :fields="bigCommentTableFields"
                    small
                    table-layout: fixed
                >
                    <template #cell(comment.feedpost_id)="row">
                        <b-button 
                            class="mr-2"
                            size="sm"
                            @click="openFeedContent(row.item.comment.feedpost_id, row.item.comment)" 
                            style="width: 100px; word-break: break-all"
                        >
                            글 이동
                        </b-button>
                    </template>
                    
                    <template #cell(id)="row">
                        <b-button 
                            class="mr-2"
                            size="sm"
                            style="width: 100px; word-break: break-all"
                            @click="openFeedComment(row.item.user.data.uuid, row.item.id)"
                        >
                            {{row.item.id}}
                        </b-button>
                    </template>
                    <template #cell(user.email)="row">
                        <b-button 
                            class="mr-2"
                            size="sm"
                            @click="openAdminUserPage(row.item.user.email)" 
                            style="width: 100px; word-break: break-all"
                        >
                        {{row.item.user.email}}
                        </b-button>
                    </template>
                    <template #cell(comment.updated_at)="row">
                        {{getTimeStamp(row.item.comment.updated_at)}}
                    </template>
                </b-table>
                <b-button @click="getBigCommentByEmail()" :disabled="bigCommentNext==''">
                    MORE
                </b-button>
            </b-col> -->
    </b-row>
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li
          class="page-item"
          v-for="item in createPageArray()"
          v-bind:key="item"
        >
          <a
            :style="[
              isPageButtonActive(item) ? { 'background-color': '#EC9E8E' } : {},
            ]"
            class="page-link"
            href="#"
            v-on:click="getComments(item)"
            >{{ item }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";

export default {
  name: "FeedCommentSearch",
  components: {
    NavComponent,
  },
  data() {
    return {
      tmp: [],

      commentList: [],
      commentTableFields: [
        { key: "user.uuid", label: "" },
        {
          key: "comment.comment_id",
          label: "Comment ID",
        },
        {
          key: "user.email",
          label: "email",
        },
        {
          key: "comment.content_type",
          label: "content type",
        },
        {
          key: "comment.content.text",
          label: "text",
        },
       
        {
          key: "comment.total_count_likes",
          label: "좋아요",
        },
        {
          label: "대댓글",
          key: "comment.total_count_comments",
        },
        {
          label: "멘션된 유저",
          key: "comment.mentioned_user.email",
        },
        {
          key: "comment.total_count_reports",
          label: "신고",
        },
        {
          key: "comment.is_delete",
          label: "삭제",
        },
        {
          key: "comment.hide",
          label: "숨김",
        },
        {
          key: "comment.created_at",
          label: "created_at",
        },
        
        {
          key: "comment.updated_at",
          label: "updated_at",
        },
        
        
      ],
      bigCommentList: [],
      bigCommentTableFields: [
        {
          key: "comment.feedpost_id",
          label: "FeedPost ID",
        },
        {
          key: "comment.comment_id",
          label: "Comment ID",
        },
        {
          key: "user.email",
          label: "email",
        },
        {
          key: "comment.content.type",
          label: "contentType",
        },
        {
          key: "comment.content.text",
          label: "content",
        },
        {
          key: "comment.hide",
          label: "hide",
        },
        "comment.updated_at",
      ],
      feedReasonList: ["none"],
      keyword: "",
      searchUserUuid: "",
      commentNext: null,
      bigCommentNext: null,
      loginCountry: this.$session.get("country"),
      apiBaseUrl: "",
      page: 0,
      bigPage: 0,
      isSearch: false,
      dropdownHideType: "none",
      dropdownReportType: "none",
      dropdownSearchType: "email",
      searchedKeyword: "",
      dropdownHideFilterType: 'all',
      hideCategory: {
        "none": "NONE",
        "전체숨김": "CS",
        "신고누적숨김": "REPORT",
        "작외숨": "OWN"
      },
      reportType: {
        "none": "NONE",
      },
      selectedComments: {}
    };
  },
  beforeMount() {
    this.apiBaseUrl = process.env.VUE_APP_FEED_SERVICE_URL;
    this.getComments(this.page);
    this.getReasonKey();
  },
  methods: {
    getTimeStamp(created_at) {
      return this.$moment(created_at).format("YY/MM/DD HH:mm");
    },
    getHeader() {
      return {
              Authorization: this.$session.get("token"),
              uuid: this.$session.get("uuid"),
            }
    },
    createPageArray() {
      let list = [];

      let b = parseInt(this.page / 10);
      let f = false;
      for (var i = 0; i < 12; i++) {
        if (b >= 1 && !f) {
          f = true;
          list.push(b * 10 + i - 1);
        }
        list.push(b * 10 + i);
      }

      return list;
    },

    changeHideCategoryFilterDropbox(value) {
      this.dropdownHideFilterType = value
      this.getComments(this.page)

    },
    isPageButtonActive(currentP) {
      return currentP == this.page ? true : false;
    },
    openAdminUserPage(email) {
      window.open(
        `${process.env.VUE_APP_DJANGO_URL}/ko/yp/manage/service/admin/account/user/?q=${email}`
      );
    },
    changeDropboxHideType(value) {
      this.dropdownHideType = value;
    },
    changeDropboxReportType(value) {
      this.dropdownReportType = value.text;
    },
    changeDropboxSearchType(value) {
      this.dropdownSearchType = value;
    },
    async getDefaultSearch() {
      this.dropdownSearchType = "email";
      this.dropdownHideFilterType = "all";
      this.searchedKeyword = "";
      this.keyword = "";
      this.isSearch = false;
      this.page = 0;
      this.getComments(this.page);
    },
    createUrl(page) {
      let apiURL = "";
      const baseUrl = `${this.apiBaseUrl}/management/comments/search`
      const queryParams = new URLSearchParams();
      if (page) {
          queryParams.append('page', page)
      } else {
        queryParams.append('page', 0)
      }

      if (this.dropdownHideFilterType != 'all') {
        queryParams.append('hide[0]', this.hideCategory[this.dropdownHideFilterType])
      }

      if (this.isSearch) {
        const keyword = this.keyword.trim();
        switch (this.dropdownSearchType) {
          case "email":
            queryParams.append('email', keyword);
            break;
          case "callRoom":
            queryParams.append('callRoomId', keyword);
            break;
          case "text":
            queryParams.append('text', keyword);
            break;
        }
        apiURL = `${baseUrl}?${queryParams.toString()}`
      } else {
        apiURL = `${this.apiBaseUrl}/management/comments/by-country?${queryParams.toString()}`;
      }
      return apiURL
    },
  getComments(p) {
      const url = this.createUrl(p)
      this.$http
        .get(url, {
          headers: {
            Authorization: this.$session.get("token"),
            uuid: this.$session.get("uuid"),
          },
        })
        .then((res) => {
          const comments = res.data.data.comments;
          
          if (res.status === 200) {
            this.page = p;

            let items = [];

            for (let it of comments) {
              if (!it){
                continue
              }

              const comment = it.comment;
              
              
              switch (comment.access) {
                case "PUBLIC":
                  comment.access = "전체";
                  break;
                case "PRIVATE":
                  comment.access = "팔로우";
                  break;
              }

              switch (comment.hide) {
                case "CS":
                  comment.hide = "전체숨김";
                  break;
                case "REPORT":
                  comment.hide = "신고누적숨김";
                  break;
                case "OWN":
                  comment.hide = "작외숨";
                  break;
                case "NONE":
                  comment.hide = "none";
                  break;
              }

              switch (comment.content.type) {
                case "IMAGE":
                  comment.content_type = "사진";
                  break;
                case "AUDIO":
                  comment.content_type = "음성";
                  break;
                case "CALL_ROOM":
                  comment.content_type = "룸공유";
                  break;
                case "NORMAL":
                  comment.content_type = "일반";
                  break;
                
                
              }
              
      
              if (comment.content.text.length >= 50) {
                comment.content.text =
                  comment.content.text.substring(0, 50) + "...";
              }

              items.push(it);
              }
            
            this.commentList = items;
          }
        })
        .catch((err) => {
          console.log(err)
          alert(err);
        });
    },
    async getCommentByEmail() {
      if (this.keyword === "") {
        return
      }
      if (!this.isSearch) {
        this.page = 0;
        this.isSearch = true;
      }
      this.searchedKeyword = this.keyword.trim();
      this.getComments(this.page);
    },
    async getBigCommentByEmail() {
      await this.$http
        .get(
          `${this.apiBaseUrl}/management/comments/re/search?page=${
            this.bigPage
          }&email=${this.keyword.trim()}`,
          {
            headers: {
              Authorization: this.$session.get("token"),
              uuid: this.$session.get("uuid"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (this.searchUserUuid != res.data.data.uuid) {
              // this.bigCommentList = []
              // this.bigCommentNext = null
            }
            this.bigCommentList = [
              ...this.bigCommentList,
              ...res.data.data.comments,
            ];
            this.bigPage++;

            this.searchUserUuid = res.data.data.uuid;
          }
        })
        .catch((err) => {
          alert(err);
        });

     
    },
    async getReasonKey() {
      this.$http
      .get(process.env.VUE_APP_API + "/management/report/control_reasons/", {
        headers: { Authorization: this.$session.get("token") },
      })
      .then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((reason) => {
            let translatedText = "";
            switch (this.loginCountry){
              case "EN":
                translatedText = reason.description_en;
                break;
              case "JP":
                translatedText = reason.description_ja;
                break;
              default:
                translatedText = reason.description_ko;
                break;
            }
            if (reason.key.startsWith("FEED")) {
              this.feedReasonList.push(translatedText);
              this.reportType[translatedText] = reason.key;
            }
          });
        }
      })
      .catch((err) => {
        alert("report reasons Error" + err);
      });
    },

    openFeedComment(userId, commentId) {
      const routeData = this.$router.resolve({
        name: "feedComment",
        params: { user_id: userId, comment_id: commentId },
      });
      window.open(routeData.href, "_blank");
    },

    openFeedContent(feed_id, feed) {
      const routeData = this.$router.resolve({
        name: "feedContent",
        params: { id: feed_id, feed: feed },
      });
      window.open(routeData.href, "_blank");
    },
    checkboxChange(comment_id, uuid) {
      const exists = Object.keys(this.selectedComments).includes(comment_id);


      if (exists) {
        this.$delete(this.selectedComments, uuid);
      } else {
        
        this.$set(this.selectedComments, comment_id, uuid);
      }
    },
    async hideActionButton() {
      await this.$http
        .put(
          `${this.apiBaseUrl}/management/comments/batch`,
          {
            commentIds: Object.keys(this.selectedComments),
            data: {
              hide: this.hideCategory[this.dropdownHideType],
            },
          },
          {
            headers:this.getHeader()
          }
        )
        .then((res) => {
          if (res.status === 200) {
            alert("success");
            this.selectedComments = {};
          }
        })
        .catch((err) => {
          alert(err);
        });
      this.$router.go();
    },
    async createReportButton() {
     
      if(this.reportType[this.dropdownReportType] == "NONE"){
        alert("신고 사유를 선택해주세요");
      } else if(Object.keys(this.selectedComments).length>1) {
        alert("한개의 댓글만 선택해주세요");
      } else {
        let userUUID = "";
        this.commentList.forEach((comment) => {
          if (Object.keys(this.selectedComments).includes(comment.comment.comment_id)) {
            userUUID = comment.user.uuid;
            
          }
        });
        await this.$http
        .post(`${process.env.VUE_APP_API}/report/v4/report`, {
          "reason_key": this.reportType[this.dropdownReportType],
          "report_content": "관리자 신고",
          "domain": "COMMENT",
          "obj_id": Object.keys(this.selectedComments)[0]
        }, {
          headers: {
            'Authorization': this.$session.get('token'),
            'Remote-user': userUUID
          }
        }).then((res) => {
          if(res.status === 201) {
            alert("success");
            this.selectedComments = {};
          }else if(res.status === 200) {
            alert("이미 관리자 신고처리 된 데이터입니다");
          }
        }).catch((err) => {
          alert(err);
        })
      }
      this.$router.go();
    },
  },
};
</script>
