import axiosInstance from "./axiosInstance";

const urls = {
  recentReports: "/management/report/reports/recent/",
};

/**
 * 유저의 최근 신고를 가져오는 API
 * @param {} page
 * @param {*} userId
 * @param {*} headers
 * @returns
 */
export async function fetchRecentReports(page, userId, headers) {
  try {
    const response = await axiosInstance.django.get(urls.recentReports, {
      params: {
        page: page,
        user_id: userId,
      },
      headers: headers,
    });
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch recent reports");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function fetchReports(page, userId, headers) {
  try {
    const response = await axiosInstance.django.get(
      "/management/report/reports/",
      {
        params: {
          page: page,
          user_id: userId,
        },
        headers: headers,
      }
    );
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch reports");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function fetchReportReasonKeysAiEvaludated(reportId, headers) {
  try {
    const response = await axiosInstance.django.get(
      `/report/v1/reports/${reportId}/report-reasons-ai-evaluated/`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch report reason keys ai evaluated");
    }
  } catch (error) {
    console.error("fetchReportReasonKeysAiEvaludated", error);
    throw error;
  }
}
