import axiosInstance from "./axiosInstance";

export async function fetchAudioMetadataByRoomId(room, headers) {
  try {
    const response = await axiosInstance.django.get(
      `/voice/v1/matching-logs/${room}/audio-files/call/metadata/`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch audio metadata");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
