import axiosInstance from "./axiosInstance";

/**
 * 사용자 제재 가져오는 API
 * @param {} page
 * @param {*} userId
 * @param {*} headers
 * @returns
 */
export async function fetchUserControls(page, userId, headers) {
  try {
    const response = await axiosInstance.django.get(
      "/management/report/user_controls/",
      {
        params: {
          page: page,
          user_id: userId,
        },
        headers: headers,
      }
    );
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch user controls");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
