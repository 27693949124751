import axios from "axios";

const baseURL = process.env.VUE_APP_API;

const djangoApiInstance = axios.create({
  baseURL: baseURL,
  timeout: 3000,
});

export default {
  django: djangoApiInstance,
};
