<template>
  <div>
    <NavComponent />
    <b-container>
      <p>this is call content</p>
    </b-container>
    <b-container>
      <div>
        <div
          :style="{
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '36%',
          }"
        >
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <span
              :style="{
                backgroundColor: 'lightblue',
                padding: '3px',
              }"
              >{{ report.userId }}</span
            >
            <div
              :style="{
                marginRight: '5px',
              }"
            >
              : 신고 한 유저
            </div>
          </div>
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <span :style="{ backgroundColor: 'lightcoral', padding: '3px' }">{{
              report.targetUserId
            }}</span>
            <div
              :style="{
                marginRight: '5px',
              }"
            >
              : 신고 당한 유저
            </div>
          </div>
        </div>
      </div>
      <b-table :items="transcripts" :fields="fields" responsive="sm" small>
        <template #head(user_id)>
          <span>User ID</span>
        </template>
        <template #cell(user_id)="data">
          <div :style="{ backgroundColor: getUserColor(data.item.user_id) }">
            {{ data.item.user_id }}
          </div>
        </template>

        <template #head(start)>
          <span>Start</span>
        </template>
        <template #cell(start)="data">
          {{ data.item.start }}
        </template>

        <template #head(end)>
          <span>End</span>
        </template>
        <template #cell(end)="data">
          {{ data.item.end }}
        </template>

        <template #head(text)>
          <span>Text</span>
        </template>
        <template #cell(text)="data">
          <div class="text-left">{{ data.item.text }}</div>
        </template>
      </b-table>
    </b-container>
  </div>
</template>
<style>
.b-table td {
  padding: 0.5rem; /* Adjust as necessary */
}
.b-table th {
  padding: 0.5rem; /* Adjust as necessary */
}
</style>

<script>
import NavComponent from "../NavComponent";
import * as apis from "../../apis";

export default {
  name: "CallContent",
  components: {
    NavComponent,
  },
  beforeCreate() {
    if (this.$session.get("token") === null) {
      this.$router.push({ name: "Login" });
    }
    if (!this.$route.params.roomId) {
      this.$router.push({ name: "Home" });
    }
    this.roomId = this.$route.params.roomId;

    apis.transcripts
      .fetchTranscriptByRoomId(this.roomId, {
        Authorization: this.$session.get("token"),
      })
      .then((transcripts) => {
        if (!transcripts.length) {
          return;
        }
        const groupedTranscripts = [];
        let currentGroup = [];
        let currentStart = transcripts[0].start;

        transcripts.forEach((transcript) => {
          if (
            transcript.start - currentStart < 60 &&
            transcript.user_id === currentGroup[0]?.user_id
          ) {
            currentGroup.push(transcript);
          } else {
            if (currentGroup.length) {
              groupedTranscripts.push({
                user_id: currentGroup[0].user_id,
                start: currentGroup[0].start,
                end: currentGroup[currentGroup.length - 1].end,
                text: currentGroup.map((t) => t.text).join(" "),
              });
            }
            currentGroup = [transcript];
            currentStart = transcript.start;
          }
        });

        if (currentGroup.length) {
          groupedTranscripts.push({
            user_id: currentGroup[0].user_id,
            start: currentGroup[0].start,
            end: currentGroup[currentGroup.length - 1].end,
            text: currentGroup.map((t) => t.text).join(" "),
          });
        }

        // apply format

        this.transcripts = groupedTranscripts.map((transcript) => {
          return {
            ...transcript,
            start: this.formatTimeInMinute(transcript.start),
            end: this.formatTimeInMinute(transcript.end),
          };
        });

        this.userIds = [
          ...new Set(this.transcripts.map((transcript) => transcript.user_id)),
        ];
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      roomId: this.$route.params.roomId,
      messageTs: 0,
      transcripts: [],
      report: {
        userId: this.$route.query.userId,
        targetUserId: this.$route.query.targetUserId,
      },
      fields: [
        { key: "user_id", label: "User ID" },
        { key: "start", label: "Start" },
        { key: "end", label: "End" },
        { key: "text", label: "Text" },
      ],
    };
  },
  methods: {
    getUserColor(userId) {
      const colors = {
        [this.report.targetUserId]: "lightcoral",
        [this.report.userId]: "lightblue",
      };
      return colors[userId];
    },
    formatTimeInMinute(secondStr) {
      // delete milliseconds,

      const minutes = Math.floor(secondStr / 60);
      const seconds = Math.floor(secondStr % 60);
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
};
</script>
