<template>
    <div id="app">
        <NavComponent />
        <div v-if="commentData != null">
            <b-container>
                <b-row>
                    <b-col class="nameFields" cols="2">
                        feed_id:
                        <!-- 고정 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        <b-button @click="openFeedContent(commentData.comment.feedpost_id)">
                            {{commentData.comment.feedpost_id}}
                        </b-button>
                    </b-col>
                </b-row>

                <b-row>
                     <b-col class="nameFields" cols="2">
                        kind:

                    </b-col> 
                    <b-col class="feedData" cols="8">
                        {{commentData.type}}
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="nameFields" cols="2">
                        user_email:
                        <!-- 고정 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        <b-button @click="openAdminUserPage(commentData.user.email)">
                        {{commentData.user.email}}
                        </b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="nameFields" cols="2">
                        content_type:
                        <!-- 고정 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        {{commentData.comment.content.type}}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="nameFields" cols="2">
                        created_at:
                        <!-- 고정 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        {{getTimeStamp(commentData.comment.created_at)}}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="nameFields" cols="2">
                        toUser:
                        <!-- 고정 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        {{mentioned_user.email}}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="nameFields" cols="2">
                        content: 
                        <!-- 수정가능 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        <b-form-textarea
                                id="textarea"
                                v-model="changedCommentContent"
                                :placeholder="commentData.comment.content.text"
                                rows="3"
                                max-rows="6"
                            >
                        </b-form-textarea>
                    </b-col>
                </b-row>
                <!-- <b-row>
                    <b-col class="nameFields" cols="2">
                        comments: 
                    </b-col>
                    <b-col class="feedData" cols="8">
                        <b-button @click="getComments(feedData.origin_id),$bvModal.show('commentModal')">
                            {{feedData.commentsCount}}
                        </b-button>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col class="nameFields" cols="2">
                        likes: 
                        <!-- 고정 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        {{commentData.comment.total_count_likes}}
                    </b-col>
                </b-row>
                <!-- <b-row>
                    <b-col class="nameFields" cols="2">
                        manage_likes: 
                    </b-col>
                    <b-col class="feedData" cols="8">
                        <b-form-input 
                            id="input-small" 
                            size="sm" 
                            :placeholder="String(feedData.manageLikesCount)"
                            v-model="changedManageLikesCount"
                        ></b-form-input>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col class="nameFields" cols="2">
                        reports: 
                        <!-- 고정 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        {{commentData.comment.total_count_reports}}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="nameFields" cols="2">
                        hide:
                        <!-- 수정 가능 // enum-->
                    </b-col>
                    <b-dropdown id="dropdown-1" :text="currentHideType" class="m-md-2">
          <b-dropdown-item @click="changeHideType('none')"
            >none</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideType('전체숨김')"
            >전체숨김</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideType('신고누적숨김')"
            >신고누적숨김</b-dropdown-item
          >
          <b-dropdown-item @click="changeHideType('작외숨')"
            >작외숨</b-dropdown-item
          >
        </b-dropdown>
                </b-row>

                <b-row>
                    <b-col class="isDeleteFields" cols="2">
                    isDelete:
                    <!-- 수정 가능 // enum-->
                    </b-col>
                    <b-dropdown id="dropdown-5" :text="currentDeleteType" class="m-md-2">
                    <b-dropdown-item @click="changeIsDelete('true')"
                        >true</b-dropdown-item
                    >
                    <b-dropdown-item @click="changeIsDelete('false')"
                        >false</b-dropdown-item
                    >
                    </b-dropdown>
                </b-row>

                <b-row>
                    <b-col class="nameFields" cols="2">
                        content_url:
                        <!-- 삭제가능 -->
                    </b-col>
                    <b-col class="feedData" cols="8">
                        <div v-if="commentData.comment.content.type =='GIF'">
                            <b-img :src="commentData.comment.content.url[0]['url']"/>
                            <br/>
                        </div>
                        <div v-else-if="commentData.comment.content.type =='AUDIO'">
                            <audio
                                controls
                                :src="commentData.comment.content.url[0]['url']">
                            </audio>
                            <br/>
                            {{commentData.comment.content.url[0]['url']}}
                        </div>
                        <div v-else-if="commentData.comment.content.type=='IMAGE'">
                            <div v-for="(imageUrl, index) in commentData.data.contentUrl" v-bind:key="index">
                                <b-img :src="imageUrl.url"/>
                                {{imageUrl.url}}
                            </div>
                        </div>
                        <div v-else-if="commentData.comment.content.type == 'CALL_ROOM'">
                            <b-button @click="openCallRoomPage(commentData.comment.content.url[0].id)">
                                {{ commentData.comment.content.url[0].id }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-button style="margin-right: 10px;" @click="updateCommentContent()">UPDATE</b-button>
                <!-- <b-button style="margin-left: 10px;" @click="deleteCommentContent()">DELETE</b-button> -->
            </b-container>
        </div>
    </div>
</template>

<script>
import NavComponent from './NavComponent.vue'

export default {
    name: 'FeedComment',
    components: {
        NavComponent
    },
    data() {
        return {
            commentData: null,
            currentHideType: null,
            changedCommentContent: '',
            loginCountry: this.$session.get('country'),
            apiBaseUrl: '',
            contentURL: [],
            contentType: '',
            mentioned_user: '',
            currentDeleteType: null
        }
    },
    beforeMount() {
        this.apiBaseUrl = process.env.VUE_APP_FEED_SERVICE_URL;

        this.$http.get(`${this.apiBaseUrl}/management/comments/${this.$route.params.comment_id}`, {
                headers: {
                    Authorization: this.$session.get("token"),
                    uuid: this.$session.get("uuid"),
                }
            }).then((res) => {
                if (res.status === 200) {
                    this.commentData = res.data.data

                    if(res.data.data.comment.hide == "NONE") {
                        this.currentHideType = "none";
                    } else if(res.data.data.comment.hide == "CS") {
                        this.currentHideType = "전체숨김"
                    } else if(res.data.data.comment.hide == "REPORT") {
                        this.currentHideType = "신고누적숨김"
                    } else if(res.data.data.comment.hide == "OWN") {
                        this.currentHideType = "작외숨"
                    }

                    this.changedCommentContent = res.data.data.comment.content.text
                    this.contentURL = res.data.data.comment.content.url
                    this.contentType = res.data.data.comment.content.type

                    if (res.data.data.type == 'comment') {
                        this.mentioned_user = '맨션댓글 아님'
                    } else {
                        this.mentioned_user = res.data.data.comment.mentioned_user.email
                    }

                    if(res.data.data.comment.is_delete) {
                        this.currentDeleteType = 'true';
                    } else {
                        this.currentDeleteType = 'false';
                    }

                }
            })
            .catch((err) => {
                alert(err)
            })
    },
    methods:{
        getTimeStamp(created_at){
            return this.$moment(created_at).format("YY/MM/DD_HH:mm")
        },
        changeIsDelete(del) {
            this.currentDeleteType = del;
        },
        openAdminUserPage(email){
            window.open(`${process.env.VUE_APP_DJANGO_URL}/ko/yp/manage/service/admin/account/user/?q=${email}`)
        },
        openCallRoomPage(callRoom) {
            window.open(
                `${process.env.VUE_APP_DJANGO_URL}/ko/yp/manage/service/admin/voice/roomcallwaitingroom/${callRoom}/change/`
            );
        },
        changeHideType(type){
            this.currentHideType = type
        },
        updateCommentContent(){

            let hideType = ''
            if(this.currentHideType == "none") {
                hideType = "NONE";
            } else if(this.currentHideType == "전체숨김") {
                hideType = "CS"
            } else if(this.currentHideType == "신고누적숨김") {
                hideType = "REPORT"
            } else if(this.currentHideType == "작외숨") {
                hideType = "OWN"
            }      

            this.$http.put(`${this.apiBaseUrl}/management/comments/${this.$route.params.comment_id}`, 
            {
                contentType: this.contentType,
                contentURL: this.contentURL,
                text: this.changedCommentContent,
                hide: hideType,
                isDelete: this.currentDeleteType == 'true' ? true : false
            }, {
                headers: { 
                    'Authorization': this.$session.get('token'),
                    uuid: this.$session.get("uuid"),
                }
            })
            .then((res) => {
                if (res.status === 200){
                    alert('success')
                    this.$router.go()
                }
            })
            .catch((err) => {
                alert(err)
            })
        },
        deleteCommentContent(){

        },
        openFeedContent(id){
            const routeData = this.$router.resolve({name : 'feedContent', params: {id: id}})
            window.open(routeData.href, '_blank')
        }
    }
}
</script>

<style scoped>

.nameFields{
    font-weight: bold;
    text-align: right;
}

.feedData{
    text-align: left;
}

</style>