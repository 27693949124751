<template>
  <b-button-group>
    <b-button
      class="acceptBtn"
      pill
      variant="success"
      @click="$emit('pend-report')"
    >
      {{ contentString.pending[loginCountry] }}
    </b-button>
    <b-button
      class="acceptBtn"
      pill
      variant="success"
      @click="$emit('apply-direct-control')"
    >
      {{ contentString.pass[loginCountry] }}
    </b-button>
    <b-button
      class="acceptBtn"
      pill
      variant="success"
      @click="$emit('apply-to-cleanliness-lab')"
    >
      {{ contentString.applyScore[loginCountry] }}
    </b-button>
    <b-button
      class="acceptBtn"
      pill
      variant="success"
      @click="$emit('reject-report')"
    >
      {{ contentString.notApplyScore[loginCountry] }}
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  props: {
    contentString: Object,
    loginCountry: String,
  },
};
</script>

<style scoped>
.acceptBtn {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  width: 160px;
  font-size: small;
}
</style>
