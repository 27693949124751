<template>
  <b-button-group>
    <b-button
      v-for="(label, action) in actions"
      :key="action"
      @click="handleClick(action)"
      :pressed="actionBtn[action]"
      class="resultBtn"
      pill
      size="sm"
      variant="outline-info"
    >
      {{ label }}
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  props: {
    contentString: Object,
    loginCountry: String,
    actionBtn: Object,
  },
  computed: {
    actions() {
      return {
        WARNING: this.contentString.warning[this.loginCountry],
        PAUSE_DAY1: this.contentString.pause_1[this.loginCountry],
        PAUSE_DAY3: this.contentString.pause_3[this.loginCountry],
        PAUSE_DAY5: this.contentString.pause_5[this.loginCountry],
        PAUSE_DAY7: this.contentString.pause_7[this.loginCountry],
        PAUSE_DAY365: this.contentString.pause_365[this.loginCountry],
        BANNED: this.contentString.ban[this.loginCountry],
      };
    },
  },
  methods: {
    handleClick(action) {
      this.$emit("update-action", action); // Emit event instead of mutating prop
    },
  },
};
</script>

<style scoped>
.resultBtn {
  margin-left: -8%;
  margin-right: 3%;
  margin-top: 5px;
  word-break: keep-all;
}
</style>
